import { useLoading, TailSpin } from "@agney/react-loading";
import type { FC } from "react";

import {
  Container,
  BackgroundContainer,
  IconContainer,
  LoadingText,
} from "./StyledComponents";



export interface LoadingIndicatorProps {
  className?: string;
  text?: string;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  className,
  text = "Loading...",
}) => {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <TailSpin />,
  });

  return (
    <Container className={className}>
      <BackgroundContainer>
        <IconContainer {...containerProps}>{indicatorEl}</IconContainer>
        {text && <LoadingText size="h5">{text}</LoadingText>}
      </BackgroundContainer>
    </Container>
  );
};

export default LoadingIndicator;
