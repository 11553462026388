import { gql } from "@kv/apollo-client";

import AuthSchema from "./auth.gql";
import BusinessUnitSchema from "./businessUnit.gql";
import ContentItemSchema from "./contentItem.gql";
import ContentItemTypeSchema from "./contentItemType.gql";
import UserGroupSchema from "./group.gql";
import JobSchema from "./job.gql";
import JobItemSchema from "./jobItem.gql";
import JobTypeSchema from "./jobType.gql";
import UserRoleSchema from "./role.gql";
import TagSchema from "./tag.gql";
import UserSchema from "./user.gql";
import VisibilitySchema from "./visibility.gql";



export const graphQLSchema = gql`
  scalar Upload

  ${AuthSchema}
  ${ContentItemSchema}
  ${ContentItemTypeSchema}
  ${JobItemSchema}
  ${JobSchema}
  ${JobTypeSchema}
  ${TagSchema}
  ${VisibilitySchema}
  ${BusinessUnitSchema}
  ${UserSchema}
  ${UserRoleSchema}
  ${UserGroupSchema}

  type Query {
    authTokens: AuthTokens
    currentUser: User

    jobs: [Job]
    job(id: ID!): Job

    jobTypes: [JobType]
    jobType(id: ID!): JobType

    jobItems: [JobItem]
    jobItem(id: ID!): JobItem

    contentItems: [ContentItem]
    contentItem(id: ID!): ContentItem

    contentItemTypes: [ContentItemType]
    contentItemType(id: ID!): ContentItemType

    visibilityTypes: [VisibilityType]
    visibilityType(id: ID!): VisibilityType

    businessUnits: [BusinessUnit]
    businessUnit(id: ID!): BusinessUnit

    tags: [Tag]
    tag(id: ID!): Tag

    users: [User]
    user(id: ID!): User

    userRoles: [UserRole]
    userRole(id: ID!): UserRole

    userGroups: [UserGroup]
    userGroup(id: ID!): UserGroup
  }

  type Mutation {
    userLogin(input: UserLoginInput!): UserLoginResult

    createJob(input: JobInput!): Job
    updateJob(id: ID!, input: JobInput!): Job
    deleteJob(id: ID!): ID
    deleteJobs(ids: [ID]): [ID]

    createJobItem(input: JobItemInput!): JobItem
    updateJobItem(id: ID!, input: JobItemInput!): JobItem
    deleteJobItem(id: ID!): ID
    deleteJobItems(ids: [ID]): [ID]

    createContentItem(input: ContentItemInput!): ContentItem
    updateContentItem(id: ID!, input: ContentItemInput!): ContentItem
    deleteContentItem(id: ID!): ID
    deleteContentItems(ids: [ID]): [ID]

    createBusinessUnit(input: BusinessUnitInput!): BusinessUnit
    updateBusinessUnit(id: ID!, input: BusinessUnitInput!): BusinessUnit
    deleteBusinessUnit(id: ID!): ID
    deleteBusinessUnits(ids: [ID]): [ID]

    createTag(input: TagInput!): Tag
    updateTag(id: ID!, input: TagInput!): Tag
    deleteTag(id: ID!): ID
    deleteTags(ids: [ID]): [ID]

    createUser(input: UserInput!): User
    updateUser(id: ID!, input: UserInput!): User
    deleteUser(id: ID!): ID
    deleteUsers(ids: [ID]): [ID]

    createUserGroup(input: UserGroupInput!): UserGroup
    updateUserGroup(id: ID!, input: UserGroupInput!): UserGroup
    deleteUserGroup(id: ID!): ID
    deleteUserGroups(ids: [ID]): [ID]
  }
`;
