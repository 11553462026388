import type { RefObject } from "react";
import { useEffect } from "react";



export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
) => {
  const handleClick = (e: Event) => {
    if (ref.current && !ref.current.contains(document.activeElement)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
