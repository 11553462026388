import type { QueryHookOptions } from "@kv/apollo-client";
import { useMemo } from "react";

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getInFilterValue,
  getIlikeFilterValue,
} from "./utils";
import {
  GetContentItems,
  GetContentItem,
  CreateContentItem,
  UpdateContentItem,
  DeleteContentItems,
  GetContentItemUpload,
} from "~/graphql";
import type {
  ContentItemsQueryResultData,
  ContentItemQueryResultData,
  CreateUpdateContentItemInput,
  CreateUpdateContentItemMutationResultData,
  DeleteContentItemsMutationResultData,
  ContentItemFileQueryResultData,
} from "~/types";



export const useGetContentItemsQuery =
  makeGetEntitiesQueryHook<ContentItemsQueryResultData>(GetContentItems);

export const useGetContentItemByIdQuery =
  makeGetEntityByIdQueryHook<ContentItemQueryResultData>(GetContentItem);

export const useGetContentItemFileByIdQuery =
  makeGetEntityByIdQueryHook<ContentItemFileQueryResultData>(
    GetContentItemUpload,
  );

interface SearchContentItemsByTypeQueryVars {
  contentItemTypeId?: string;
  name?: string;
  ids?: string[];
}

export const useSearchContentItemsQuery = (
  { contentItemTypeId, name, ids }: SearchContentItemsByTypeQueryVars,
  options?: QueryHookOptions<ContentItemsQueryResultData>,
) => {
  const variables = useMemo(
    () => ({
      filters: {
        contentItemTypeId,
        nameIlike: getIlikeFilterValue(name),
        idIn: getInFilterValue(ids),
      },
    }),
    [contentItemTypeId, ids, name],
  );

  return useGetContentItemsQuery(variables, options);
};

export const useCreateContentItemMutation = makeCreateEntityMutationHook<
  CreateUpdateContentItemMutationResultData,
  CreateUpdateContentItemInput
>(CreateContentItem);

export const useUpdateContentItemMutation = makeUpdateEntityMutationHook<
  CreateUpdateContentItemMutationResultData,
  CreateUpdateContentItemInput
>(UpdateContentItem);

export const useDeleteContentItemsMutation =
  makeDeleteEntitiesMutationHook<DeleteContentItemsMutationResultData>(
    DeleteContentItems,
  );
