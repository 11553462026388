import jwt from "jsonwebtoken";
import { useLocation } from "react-router-dom";

import type { RouterLocationState } from "../types";



const { REACT_APP_JWT_SECRET_KEY } = process.env;

export function getIsValidToken(token?: string) {
  if (!token || token === "") {
    return false;
  }
  if (!REACT_APP_JWT_SECRET_KEY) {
    return false;
  }

  try {
    jwt.verify(token, REACT_APP_JWT_SECRET_KEY);

    return true;
  } catch {
    return false;
  }
}

// REDIRECT PATH HOOK UTLS

export function useGetRedirectPath() {
  const location = useLocation<RouterLocationState>();
  const urlParams = location.search.slice(1);
  const params = new URLSearchParams(urlParams);

  return (
    params.get("contentRedirect") ??
    params.get("redirect") ??
    getRedirectPathFromLocalStorage()
  );
}

// REDIRECT PATH LOCALSTORAGE UTILS

export function getRedirectPathFromLocalStorage() {
  const _token = window.localStorage.getItem("redirectPath");

  return _token;
}

export function saveRedirectPathIntoLocalStorage(redirectPath: string) {
  window.localStorage.setItem("redirectPath", redirectPath);
}

export function removeRedirectPathFromLocalStorage() {
  window.localStorage.removeItem("redirectPath");
}

// TOKEN LOCALSTORAGE UTILS

export function getTokenFromLocalStorage() {
  const _token = window.localStorage.getItem("token");

  return _token;
}

export function getTokenFromURLSearchParams() {
  const urlParams = window.location.search.slice(1);
  const params = new URLSearchParams(urlParams);
  const _token = params.get("token") as string;

  window.history.pushState({}, document.title, window.location.pathname);

  return _token;
}

export function saveTokenIntoLocalStorage(token: string) {
  window.localStorage.setItem("token", token);
}

export function removeTokenFromLocalStorage() {
  window.localStorage.removeItem("token");
}
