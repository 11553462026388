import { gql } from "@kv/apollo-client";



export const UserRoleFragment = gql`
  fragment UserRoleFragment on UserRole {
    __typename
    id
    value
  }
`;

export const GetUserRoles = gql`
  query GetUserRoles($filters: UserRoleFilter) {
    userRoles(filters: $filters) {
      ...UserRoleFragment
    }
  }
  ${UserRoleFragment}
`;

export const GetUserRole = gql`
  query GetUserRole {
    userRole {
      ...UserRoleFragment
    }
  }
  ${UserRoleFragment}
`;

export const CreateUserRole = gql`
  mutation CreateUserRole($input: UserRoleInput!) {
    createUserRole(input: $input) {
      ...UserRoleFragment
    }
  }
  ${UserRoleFragment}
`;

export const UpdateUserRole = gql`
  mutation UpdateUserRole($id: ID!, $input: UserRoleInput!) {
    updateUserRole(id: $id, input: $input) {
      ...UserRoleFragment
    }
  }
  ${UserRoleFragment}
`;

export const DeleteUserRoles = gql`
  mutation DeleteUserRoles($ids: [ID]) {
    deleteUserRoles(ids: $ids) {
      ids
    }
  }
`;
