import {
  curry,
  compose,
  map,
  find,
  reject,
  reduce,
  prop,
  propEq,
  has,
  pick,
  uniqBy,
} from "ramda";

import type { SelectOption, SimpleEntity, NamedEntity } from "~/types";



export const isSimpleEntity = (
  entity: SimpleEntity | NamedEntity,
): entity is SimpleEntity => has("value", entity);

export const isNamedEntity = (
  entity: SimpleEntity | NamedEntity,
): entity is NamedEntity => has("name", entity);

export const getSelectOptionFromSimpleEntity = (
  entity: SimpleEntity,
): SelectOption => pick(["id", "value"], entity);

export const getSelectOptionFromNamedEntity = (
  entity: NamedEntity,
): SelectOption => ({
  ...entity,
  value: prop("name", entity),
});

export const getSelectOptionFromMixedEntity = (
  entity: SimpleEntity | NamedEntity,
): SelectOption | undefined => {
  if (isSimpleEntity(entity)) {
    return getSelectOptionFromSimpleEntity(entity);
  }

  if (isNamedEntity(entity)) {
    return getSelectOptionFromNamedEntity(entity);
  }

  return;
};

export const pluckSelectOptionIds = map<SelectOption, string>(prop("id"));

export const pluckSelectOptionValues = map<SelectOption, string>(prop("value"));

export const pickSelectOptionData = map<SimpleEntity, SelectOption>(
  getSelectOptionFromSimpleEntity,
);

export const pickSelectOptionDataFromNamedEntities = map<
  NamedEntity,
  SelectOption
>(getSelectOptionFromNamedEntity);

export const pickSelectOptionDataFromMixedEntities = reduce<
  SimpleEntity | NamedEntity,
  SelectOption[]
>((acc, val) => {
  const option = getSelectOptionFromMixedEntity(val);

  return option ? [...acc, option] : acc;
}, []);

export const findSelectOptionById = curry(
  (id: string, options: SelectOption[]): SelectOption | undefined =>
    find(propEq("id", id), options),
);

export const findSelectOptionByValue = curry(
  (value: string, options: SelectOption[]): SelectOption | undefined =>
    find(propEq("value", value), options),
);

export const getUniqueSelectOptions = uniqBy<SelectOption, string>(prop("id"));

export const rejectSelectOptionById = curry(
  (id: string, options: SelectOption[]) => reject(propEq("id", id), options),
);

export const getFileListFiles = (files: FileList | null | undefined) =>
  Array.from(files ?? []);

export const getFileListFileNames = compose(
  map(prop("name")),
  getFileListFiles,
);

const EMAIL_REGEXP =
  // eslint-disable-next-line max-len
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

const BD_EMAIL_REGEXP = /^[\w%+.-]+@bd.com$/;

const DATE_MM_DD_YYYY_REGEXP = /^(?:\d{2}\/){2}\d{4}$/;

export const validateEmail = (email: string) => EMAIL_REGEXP.test(email);
export const validateBDEmail = (email: string) =>
  validateEmail(email) && BD_EMAIL_REGEXP.test(email);
export const validateDateString = (dateString: string) =>
  DATE_MM_DD_YYYY_REGEXP.test(dateString);
