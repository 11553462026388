import { parseISO, format } from "date-fns/fp";



export const parseEntityDate = (date: string = new Date().toISOString()) =>
  parseISO(date);

export const formatEntityDate = (date: Date = new Date()) =>
  format("MM/dd/yyyy", date);

export const parseAndFormatEntityDate = (date?: string) =>
  formatEntityDate(parseEntityDate(date));
