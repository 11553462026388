import type { QueryHookOptions } from "@kv/apollo-client";
import { useMemo } from "react";

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  getIlikeFilterValue,
  getInFilterValue,
} from "./utils";
import { GetContentItemTypes, GetContentItemType } from "~/graphql";
import type {
  ContentItemTypesQueryResultData,
  ContentItemTypeQueryResultData,
} from "~/types";



export const useGetContentItemTypesQuery =
  makeGetEntitiesQueryHook<ContentItemTypesQueryResultData>(
    GetContentItemTypes,
  );

export const useGetContentItemTypeByIdQuery =
  makeGetEntityByIdQueryHook<ContentItemTypeQueryResultData>(
    GetContentItemType,
  );

interface SearchContentItemsTypesQueryVars {
  value?: string;
  ids?: string[];
}

export const useSearchContentItemTypesQuery = (
  { value, ids }: SearchContentItemsTypesQueryVars,
  options?: QueryHookOptions<ContentItemTypesQueryResultData>,
) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { valueIlike: getIlikeFilterValue(value) },
          { idIn: getInFilterValue(ids) },
        ],
      },
    }),
    [value, ids],
  );

  return useGetContentItemTypesQuery(variables, options);
};
