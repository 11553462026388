import styled from "astroturf/react";

import LoadingIndicator from "../LoadingIndicator";



const RedirectSplashScreenWrapper = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #fff;
  z-index: +1;
`;

function RedirectSplashScreen() {
  return (
    <RedirectSplashScreenWrapper>
      <LoadingIndicator />
    </RedirectSplashScreenWrapper>
  );
}

export default RedirectSplashScreen;
