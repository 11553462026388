import type { TruncateOptions } from "lodash";
import { truncate as truncateString } from "lodash";
import { curry } from "ramda";



export const truncate = curry(
  (options: TruncateOptions, value: string | undefined) =>
    truncateString(value, options),
);
