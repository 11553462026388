import type { FC } from "react";
import { Switch, Route } from "react-router-dom";

import AsyncPage from "./AsyncPage";
import AdminContent from "~/components/AdminContent";
import AuthenticatedContent from "~/components/AuthenticatedContent";
import NonAuthenticatedContent from "~/components/NonAuthenticatedContent";
import { RoutePaths } from "~/enums";



const AppRoutes: FC = () => (
  <Switch>
    <Route exact path={RoutePaths.LOGIN}>
      <NonAuthenticatedContent>
        <AsyncPage page="Login/Login" />
      </NonAuthenticatedContent>
    </Route>

    <Route path={RoutePaths.LOGIN_SSO}>
      <NonAuthenticatedContent>
        <AsyncPage page="Login/LoginSso" />
      </NonAuthenticatedContent>
    </Route>

    <Route path={RoutePaths.LOGIN_PUBLIC}>
      <NonAuthenticatedContent>
        <AsyncPage page="Login/LoginPublic" />
      </NonAuthenticatedContent>
    </Route>

    <Route path={RoutePaths.JOB_ITEM}>
      <AuthenticatedContent>
        <AsyncPage page="JobItem" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.JOB}>
      <AuthenticatedContent>
        <AsyncPage page="Job" />
      </AuthenticatedContent>
    </Route>

    <Route exact path={RoutePaths.JOBS_LIST}>
      <AuthenticatedContent>
        <AsyncPage page="JobsList" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.CONTENT_ITEMS_LIST}>
      <AuthenticatedContent>
        <AdminContent>
          <AsyncPage page="Content" />
        </AdminContent>
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.ADMIN}>
      <AuthenticatedContent>
        <AdminContent>
          <AsyncPage page="Admin" />
        </AdminContent>
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.USER_ACCOUNT}>
      <AuthenticatedContent>
        <AsyncPage page="UserAccount" />
      </AuthenticatedContent>
    </Route>

    <Route path={RoutePaths.POLICIES}>
      <AsyncPage page="Policies" />
    </Route>

    <Route path={RoutePaths.SANDBOX}>
      <AsyncPage page="Sandbox" />
    </Route>
  </Switch>
);

export default AppRoutes;
