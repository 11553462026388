import type { ContentItem } from "~/types";



export const getContentItemFileEndsWith = (
  contentItem: ContentItem,
  endsWithFileExtensions: string[],
) => {
  if (contentItem && contentItem.contentItemFiles) {
    return contentItem.contentItemFiles.find(({ azureFilename }) => {
      if (!azureFilename) {
        return undefined;
      }

      return endsWithFileExtensions.some(ext => azureFilename.endsWith(ext));
    });
  }
};
