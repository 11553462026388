import type { QueryHookOptions } from "@kv/apollo-client";
import { useQuery } from "@kv/apollo-client";
import { useMemo } from "react";

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getIlikeFilterValue,
  getInFilterValue,
} from "./utils";
import {
  GetUsers,
  GetUser,
  GetCurrentUser,
  CreateUser,
  UpdateUser,
  DeleteUsers,
} from "~/graphql";
import type {
  UsersQueryResultData,
  UserQueryResultData,
  CurrentUserQueryResultData,
  CreateUpdateUserInput,
  CreateUpdateUserMutationResultData,
  DeleteUsersMutationResultData,
} from "~/types";



export const useGetUsersQuery =
  makeGetEntitiesQueryHook<UsersQueryResultData>(GetUsers);

export const useGetUserByIdQuery =
  makeGetEntityByIdQueryHook<UserQueryResultData>(GetUser);

export const useGetCurrentUserQuery = () =>
  useQuery<CurrentUserQueryResultData>(GetCurrentUser);

export const useCreateUserMutation = makeCreateEntityMutationHook<
  CreateUpdateUserMutationResultData,
  CreateUpdateUserInput
>(CreateUser);

export const useUpdateUserMutation = makeUpdateEntityMutationHook<
  CreateUpdateUserMutationResultData,
  CreateUpdateUserInput
>(UpdateUser);

export const useDeleteUsersMutation =
  makeDeleteEntitiesMutationHook<DeleteUsersMutationResultData>(DeleteUsers);

interface SearchUserQueryVars {
  name?: string;
  ids?: string[];
  email?: string;
  nameIds?: string[];
  emailIds?: string[];
  roleIds?: string[];
  groupIds?: string[];
  take?: number;
  skip?: number;
  orderBy?: string;
}

export const useSearchUsersQuery = (
  {
    name,
    ids,
    email,
    nameIds,
    emailIds,
    roleIds,
    groupIds,
    take,
    skip,
    orderBy,
  }: SearchUserQueryVars,
  options?: QueryHookOptions<UsersQueryResultData>,
) => {
  const variables = useMemo(() => {
    const orFilters = [];
    if (getIlikeFilterValue(name)) {
      orFilters.push({ nameIlike: getIlikeFilterValue(name) });
    }
    if (getIlikeFilterValue(email)) {
      orFilters.push({ emailIlike: getIlikeFilterValue(email) });
    }
    if (getInFilterValue(ids)) {
      orFilters.push({ idIn: getInFilterValue(ids) });
    }
    if (getInFilterValue(nameIds)) {
      orFilters.push({ idIn: getInFilterValue(nameIds) });
    }
    if (getInFilterValue(emailIds)) {
      orFilters.push({ idIn: getInFilterValue(emailIds) });
    }
    if (getInFilterValue(roleIds)) {
      orFilters.push({ roleIdIn: getInFilterValue(roleIds) });
    }
    if (getInFilterValue(groupIds)) {
      orFilters.push({ groupIdsIn: getInFilterValue(groupIds) });
    }

    return {
      filters: {
        or: orFilters,
      },
      skip,
      take,
      orderBy,
    };
  }, [
    email,
    emailIds,
    groupIds,
    ids,
    name,
    nameIds,
    roleIds,
    skip,
    take,
    orderBy,
  ]);

  return useGetUsersQuery(variables, options);
};
