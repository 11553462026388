import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
} from "./utils";
import {
  GetVisibilityTypes,
  GetVisibilityType,
  CreateVisibilityType,
  UpdateVisibilityType,
  DeleteVisibilityTypes,
} from "~/graphql";
import type {
  VisibilityTypesQueryResultData,
  VisibilityTypeQueryResultData,
  CreateUpdateVisibilityTypeInput,
  CreateUpdateVisibilityTypeMutationResultData,
  DeleteVisibilityTypesMutationResultData,
} from "~/types";



export const useGetVisibilityTypesQuery =
  makeGetEntitiesQueryHook<VisibilityTypesQueryResultData>(GetVisibilityTypes);

export const useGetVisibilityTypeByIdQuery =
  makeGetEntityByIdQueryHook<VisibilityTypeQueryResultData>(GetVisibilityType);

export const useCreateVisibilityTypeMutation = makeCreateEntityMutationHook<
  CreateUpdateVisibilityTypeMutationResultData,
  CreateUpdateVisibilityTypeInput
>(CreateVisibilityType);

export const useUpdateVisibilityTypeMutation = makeUpdateEntityMutationHook<
  CreateUpdateVisibilityTypeMutationResultData,
  CreateUpdateVisibilityTypeInput
>(UpdateVisibilityType);

export const useDeleteVisibilityTypesMutation =
  makeDeleteEntitiesMutationHook<DeleteVisibilityTypesMutationResultData>(
    DeleteVisibilityTypes,
  );
