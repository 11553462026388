import type { ApolloError } from "@kv/apollo-client";
import { prop, map } from "ramda";



export const getErrorMessage = prop("message");
export const pluckErrorMessages = map(getErrorMessage);

const getErrorMessageWithPrefix = (prefix: string) => (error: Error) =>
  `[${prefix}]: ${getErrorMessage(error)}`;

export const getApolloErrorsByType = (error?: ApolloError) => {
  if (!error) {
    return {
      graphQLErrors: [],
      networkErrors: [],
    };
  }

  const { graphQLErrors = [], networkError } = error;
  const networkErrors = networkError ? [networkError] : [];

  return {
    graphQLErrors,
    networkErrors,
  };
};

export const getApolloErrors = (error?: ApolloError) => {
  const { graphQLErrors, networkErrors } = getApolloErrorsByType(error);

  return [...graphQLErrors, ...networkErrors];
};

export const getApolloErrorMessages = (error?: ApolloError): string[] => {
  const { graphQLErrors, networkErrors } = getApolloErrorsByType(error);
  const graphQLErrorMessages = graphQLErrors.map(
    getErrorMessageWithPrefix("GraphQL Error"),
  );
  const networkErrorMessages = networkErrors.map(
    getErrorMessageWithPrefix("Network Error"),
  );

  return [...graphQLErrorMessages, ...networkErrorMessages];
};

export const getMessageFromApolloError = (error?: ApolloError): string =>
  getApolloErrors(error).map(getErrorMessage).join(", ");
