import { useCallback, useRef } from "react";



export function useDebounce<T, Args extends unknown[]>(
  func: (...args: Args) => Promise<T>,
  delay: number,
  cancelResp: T,
): (...args: Args) => Promise<T> {
  const timer = useRef<NodeJS.Timeout>();
  const prevResolves = useRef<((value: T) => void)[]>([]);

  return useCallback(
    (...args: Args) =>
      new Promise((resolve, reject) => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        prevResolves.current.push(resolve);
        timer.current = setTimeout(async () => {
          timer.current = undefined;
          const resolverArray = prevResolves.current;
          prevResolves.current = [];
          try {
            const result = await func(...args);
            resolverArray.forEach(resolver => resolver(result));
          } catch (error) {
            resolverArray
              .slice(0, -1)
              .forEach(resolver => resolver(cancelResp));
            reject(error);
          }
        }, delay);
      }),
    [delay, func, cancelResp],
  );
}
