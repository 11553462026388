import type { MatterportSDK, ThreeNode } from "~/types";



export const createSceneNode = async (sdk: MatterportSDK) => {
  if (!sdk) {
    return;
  }

  const node: ThreeNode = await sdk.Scene.createNode();

  return node;
};
