import { uniqueId } from "lodash";
import { curry, propEq } from "ramda";

import type { TodoItem, TodoId } from "./types";



export const getNewTodoId = () => uniqueId("todo");

export const getIsTodoById = curry((id: TodoId, todo: TodoItem) =>
  propEq("id", id, todo),
);

export const toggleCompleted = ({ completed, ...restTodo }: TodoItem) => ({
  ...restTodo,
  completed: !completed,
});

export const toggleCompletedById = curry((id: TodoId, todo: TodoItem) =>
  getIsTodoById(id, todo) ? toggleCompleted(todo) : todo,
);
