import type { QueryHookOptions } from "@kv/apollo-client";
import { useMemo } from "react";

import { makeStandardQueryHook } from "./queries";
import { useAuthTokensContext } from "~/context/AuthTokens";
import type {
  GraphqlNode,
  BaseQueryVars,
  GetEntitiesQueryVars,
  GetEntityByIdQueryVars,
} from "~/types";



export const makeAuthenticatedQueryHook = <
  QueryResults,
  QueryVars extends BaseQueryVars = BaseQueryVars,
>(
  queryNode: GraphqlNode<QueryResults, QueryVars>,
) => {
  const useAuthenticatedQuery = (
    variables?: QueryVars,
    options?: QueryHookOptions<QueryResults, QueryVars>,
  ) => {
    const {
      loading: authTokenLoading,
      error: authTokenError,
      authTokens,
    } = useAuthTokensContext();

    const useStandardQuery = makeStandardQueryHook<QueryResults, QueryVars>(
      queryNode,
    );

    const { data, loading, error, refetch } = useStandardQuery(variables, {
      ...options,
      skip: options?.skip || authTokenLoading,
      //* This will automatically be merged in with the other variables.
      variables: {
        token: authTokens?.accessToken,
      } as QueryVars,
    });

    const result = useMemo(
      () => ({
        loading: loading || authTokenLoading,
        error: error ?? authTokenError,
        data,
        refetch,
      }),
      [loading, authTokenLoading, error, authTokenError, data, refetch],
    );

    return result;
  };

  return useAuthenticatedQuery;
};

export const makeGetEntitiesQueryHook = <QueryResults>(
  queryNode: GraphqlNode<QueryResults, GetEntitiesQueryVars>,
) => makeAuthenticatedQueryHook<QueryResults, GetEntitiesQueryVars>(queryNode);

export const makeGetEntityByIdQueryHook = <QueryResults>(
  queryNode: GraphqlNode<QueryResults, GetEntityByIdQueryVars>,
) =>
  makeAuthenticatedQueryHook<QueryResults, GetEntityByIdQueryVars>(queryNode);
