import type { FC } from "react";

import AppWrapper from "./AppWrapper";
import { AppContainer } from "./StyledComponents";
import AppRoutes from "~/pages";



interface AppProps {
  className?: string;
}

const App: FC<AppProps> = ({ className }) => (
  <AppWrapper>
    <AppContainer className={className} data-testid="ui_general_app_container">
      <AppRoutes />
    </AppContainer>
  </AppWrapper>
);

export default App;
