import { JobTypeValues, ContentItemTypeValues } from "~/enums";
import type { SelectOption } from "~/types";



export const getHumanReadableJobType = (
  jobTypeValue?: JobTypeValues,
): string => {
  switch (jobTypeValue) {
    case JobTypeValues.AR: {
      return "AR";
    }
    case JobTypeValues.VR: {
      return "VR";
    }
    case JobTypeValues.MATTERPORT: {
      return "Matterport";
    }
    case JobTypeValues.NEWSLETTER: {
      return "Publication";
    }
    case JobTypeValues.RENDERED_VT: {
      return "Rendered VT";
    }
    default: {
      return "Unknown";
    }
  }
};

export const renderJobTypeSelectOption = (option: SelectOption) =>
  getHumanReadableJobType(option.value as JobTypeValues);

export const getJobTypeValueFromContentItemTypeValue = (
  contentItemTypeValue?: ContentItemTypeValues,
): JobTypeValues => {
  switch (contentItemTypeValue) {
    case ContentItemTypeValues.AR: {
      return JobTypeValues.AR;
    }
    case ContentItemTypeValues.VR: {
      return JobTypeValues.VR;
    }
    case ContentItemTypeValues.MATTERPORT_SCENE:
    case ContentItemTypeValues.MATTERPORT_ASSET: {
      return JobTypeValues.MATTERPORT;
    }
    case ContentItemTypeValues.NEWSLETTER: {
      return JobTypeValues.NEWSLETTER;
    }
    case ContentItemTypeValues.RENDERED_VT: {
      return JobTypeValues.RENDERED_VT;
    }
    default: {
      return JobTypeValues.AR;
    }
  }
};
