const { PUBLIC_URL, REACT_APP_MATTERPORT_APPLICATION_KEY } = process.env;

const ALPHA_NUMERIC_REGEXP = /^[\dA-Za-z]+$/;
const MATTERPORT_URL_REGEXP = /^https:\/\/my\.matterport\.com\/show\/\?m=/;

const isAlphaNumeric = (value: string) => ALPHA_NUMERIC_REGEXP.test(value);

const isMatterportUrl = (value: string) => MATTERPORT_URL_REGEXP.test(value);

const MATTERPORT_SCENE_ID_LENGTH = 11;

export const isMatterportSceneId = (value: string) =>
  value.length === MATTERPORT_SCENE_ID_LENGTH && isAlphaNumeric(value);

export const getSceneIdFromUrlOrId = (sceneUrlOrId?: string) => {
  if (!sceneUrlOrId) {
    return "";
  }

  if (isMatterportSceneId(sceneUrlOrId)) {
    return sceneUrlOrId;
  }

  if (isMatterportUrl(sceneUrlOrId)) {
    const { searchParams } = new URL(sceneUrlOrId);

    return searchParams.get("m") ?? "";
  }
};

const formatQueryString = (params: Record<string, string | undefined>) =>
  Object.entries(params).reduce((acc, [key, value = ""]) => {
    if (!key) {
      return acc;
    }

    if (!acc) {
      return `${key}=${value}`;
    }

    return `${acc}&${key}=${value}`;
  }, "");

export const getShowcaseUrl = (sceneUrlOrId: string) => {
  const queryString = formatQueryString({
    m: getSceneIdFromUrlOrId(sceneUrlOrId),
    play: "1",
    qs: "1",
    log: "0",
    title: "0",
    applicationKey: REACT_APP_MATTERPORT_APPLICATION_KEY,
  });

  return `${PUBLIC_URL}/showcase-bundle/showcase.html?${queryString}`;
};
