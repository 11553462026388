import type { FC } from "react";

import AsyncContent from "~/components/AsyncContent";
import { useAuthTokensContext } from "~/context";



const NonAuthenticatedContent: FC = ({ children }) => {
  const { loading, error } = useAuthTokensContext();

  return (
    <AsyncContent loading={loading} error={error}>
      {children}
    </AsyncContent>
  );
};

export default NonAuthenticatedContent;
