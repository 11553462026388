import type { FC } from "react";
import { useCallback, useMemo } from "react";

import RedirectSplashScreen from "../RedirectSplashScreen";
import RedirectWithNotification from "../RedirectWithNotification";
import {
  useGetRedirectPath,
  removeRedirectPathFromLocalStorage as cleanup,
} from "~/utils";



enum RedirectPathTypes {
  CONTENT_ITEM = "content_item",
  PAGE = "page",
}

const RedirectHandler: FC = () => {
  const redirectPath = useGetRedirectPath();

  const redirectPathType = useMemo(() => {
    const contentItemSubstrings = ["/server/", "/c/"];
    const isContentItem = contentItemSubstrings.some(substring =>
      redirectPath?.includes(substring),
    );
    if (isContentItem) {
      return RedirectPathTypes.CONTENT_ITEM;
    }

    const pageSubstrings = ["job", "content", "admin"];
    const isPage = pageSubstrings.some(substring =>
      redirectPath?.includes(substring),
    );
    if (isPage) {
      return RedirectPathTypes.PAGE;
    }
  }, [redirectPath]);

  const handleRedirectToContentItem = useCallback(() => {
    const _pathFromContentItemUrl = new URL(redirectPath || "");
    window.open(_pathFromContentItemUrl.pathname || "", "_self");
  }, [redirectPath]);

  switch (redirectPathType) {
    /**
     * If redirect URL is for a Content Item, just auto-redirect. This used to
     * be a conformation modal, but this changed to an auto-redirect on
     * 07/28/2023
     */

    case RedirectPathTypes.CONTENT_ITEM:
      cleanup();
      handleRedirectToContentItem();

      return <RedirectSplashScreen />;

    /**
     * Auto-redirect to AreaXT page with a nice notification
     */
    case RedirectPathTypes.PAGE:
      cleanup();

      return (
        <RedirectWithNotification
          to={redirectPath || "/"}
          notification="Redirected"
          appearance="success"
          notificationTimeout={3000}
        />
      );

    /**
     * Do nothing - clear out the localStorage and proceed.
     */
    default:
      cleanup();

      return null;
  }
};

export default RedirectHandler;
