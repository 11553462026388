import { gql } from "@kv/apollo-client";

import { BusinessUnitFragment } from "./businessUnits";
import { JobFragment } from "./jobs";
import { UserGroupFragment } from "./userGroups";
import { UserRoleFragment } from "./userRoles";



export const UserFragment = gql`
  fragment UserFragment on User {
    __typename
    id
    name
    email
    isEnabled
    isBdSsoUser
    isCiamSsoUser
    lastActiveAt
    expiresAt
    role {
      ...UserRoleFragment
    }
    groups {
      ...UserGroupFragment
    }
    businessUnits {
      ...BusinessUnitFragment
    }
    editableJobs {
      ...JobFragment
    }
  }
  ${UserRoleFragment}
  ${UserGroupFragment}
  ${BusinessUnitFragment}
  ${JobFragment}
`;

export const GetUsers = gql`
  query GetUsers(
    $filters: UserFilter
    $skip: Int
    $take: Int
    $orderBy: String
  ) {
    users(filters: $filters, skip: $skip, take: $take, orderBy: $orderBy) {
      ...UserFragment
    }
    usersCount(filters: $filters)
  }
  ${UserFragment}
`;

export const GetUser = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const CreateUser = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const UpdateUser = gql`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const DeleteUsers = gql`
  mutation DeleteUsers($ids: [ID]) {
    deleteUsers(ids: $ids) {
      ids
    }
  }
`;

export const GetCurrentUser = gql`
  query GetCurrentUser {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;
