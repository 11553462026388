import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
} from "./utils";
import {
  GetJobItems,
  GetJobItem,
  CreateJobItem,
  UpdateJobItem,
  DeleteJobItems,
} from "~/graphql";
import type {
  JobItemsQueryResultData,
  JobItemQueryResultData,
  CreateUpdateJobItemInput,
  CreateUpdateJobItemMutationResultData,
  DeleteJobItemsMutationResultData,
} from "~/types";



export const useGetJobItemsQuery =
  makeGetEntitiesQueryHook<JobItemsQueryResultData>(GetJobItems);

export const useGetJobItemByIdQuery =
  makeGetEntityByIdQueryHook<JobItemQueryResultData>(GetJobItem);

export const useCreateJobItemMutation = makeCreateEntityMutationHook<
  CreateUpdateJobItemMutationResultData,
  CreateUpdateJobItemInput
>(CreateJobItem);

export const useUpdateJobItemMutation = makeUpdateEntityMutationHook<
  CreateUpdateJobItemMutationResultData,
  CreateUpdateJobItemInput
>(UpdateJobItem);

export const useDeleteJobItemsMutation =
  makeDeleteEntitiesMutationHook<DeleteJobItemsMutationResultData>(
    DeleteJobItems,
  );
