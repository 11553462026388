import type { FC } from "react";
import { Redirect } from "react-router-dom";

import AsyncContent from "~/components/AsyncContent";
import { useCurrentUserContext } from "~/context";
import { RoutePaths } from "~/enums";



const AdminContent: FC = ({ children }) => {
  const { loading, error, isAdminUser } = useCurrentUserContext();

  return (
    <AsyncContent loading={loading} error={error}>
      {!isAdminUser ? <Redirect to={RoutePaths.JOBS_LIST} /> : <>{children}</>}
    </AsyncContent>
  );
};

export default AdminContent;
