import { compose, prop, propOr, map, join } from "ramda";
import { v4 as uuid } from "uuid";

import type { EntityBase } from "~/types";



export const assignId = <Entity extends EntityBase>(
  item: Omit<Entity, "id">,
): Entity => ({ ...item, id: uuid() } as Entity);

export const assignIdMany = <Entity extends EntityBase>(
  items: Omit<Entity, "id">[],
) => items.map(assignId);

export const getEntityId = prop("id");

export const pluckEntityIds = map<EntityBase, string>(getEntityId);

export const renderSimpleEntity = propOr("", "value");

export const renderNamedEntity = propOr("", "name");

export const renderSimpleEntitiesList = compose(
  join(", "),
  map(renderSimpleEntity),
);

export const renderNamedEntitiesList = compose(
  join(", "),
  map(renderNamedEntity),
);
