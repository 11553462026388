import { gql } from "@kv/apollo-client";



export const VisibilityTypeFragment = gql`
  fragment VisibilityTypeFragment on VisibilityType {
    __typename
    id
    value
  }
`;

export const GetVisibilityTypes = gql`
  query GetVisibilityTypes {
    visibilityTypes {
      ...VisibilityTypeFragment
    }
  }
  ${VisibilityTypeFragment}
`;

export const GetVisibilityType = gql`
  query GetVisibilityType {
    visibilityType {
      ...VisibilityTypeFragment
    }
  }
  ${VisibilityTypeFragment}
`;

export const CreateVisibilityType = gql`
  mutation CreateVisibilityType($input: VisibilityTypeInput!) {
    createVisibilityType(input: $input) {
      ...VisibilityTypeFragment
    }
  }
  ${VisibilityTypeFragment}
`;

export const UpdateVisibilityType = gql`
  mutation UpdateVisibilityType($id: ID!, $input: VisibilityTypeInput!) {
    updateVisibilityType(id: $id, input: $input) {
      ...VisibilityTypeFragment
    }
  }
  ${VisibilityTypeFragment}
`;

export const DeleteVisibilityTypes = gql`
  mutation DeleteVisibilityTypes($ids: [ID]) {
    deleteVisibilityTypes(ids: $ids) {
      ids
    }
  }
`;
