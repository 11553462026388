import type { ApolloError } from "@kv/apollo-client";
import type { FC } from "react";
import { useMemo } from "react";

import { getApolloErrorMessages } from "~/utils";



interface ApolloErrorsProps {
  error?: ApolloError;
}

const ApolloErrors: FC<ApolloErrorsProps> = ({ error }) => {
  const messages = useMemo(() => getApolloErrorMessages(error), [error]);

  if (messages.length === 0) {
    return null;
  }

  return (
    <>
      {messages.map((message, index) => (
        <p key={`${index}-${message}`}>{message}</p>
      ))}
    </>
  );
};

export default ApolloErrors;
