import { pipe, propOr, map, filter } from "ramda";

import type { Tag, Job } from "~/types";



const getNamesFromTags = pipe(
  map<Tag, string>(propOr("", "value")),
  filter(Boolean),
);

const getTagsFromJob = propOr([], "tags");

export const getTagValuesFromJob = (job?: Job) =>
  getNamesFromTags(getTagsFromJob(job));
