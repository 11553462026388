import { ApolloProvider } from "@kv/apollo-client";
import { OverlayProvider } from "@react-aria/overlays";
import type { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import { apolloClient } from "~/apollo";
import ErrorBoundary from "~/components/ErrorBoundary";
import { AuthTokensProvider, CurrentUserProvider } from "~/context";
import store from "~/state";



const AppWrapper: FC = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <ToastProvider autoDismiss autoDismissTimeout={5000}>
        <OverlayProvider>
          <BrowserRouter>
            <AuthTokensProvider>
              <CurrentUserProvider>
                <ErrorBoundary>{children}</ErrorBoundary>
              </CurrentUserProvider>
            </AuthTokensProvider>
          </BrowserRouter>
        </OverlayProvider>
      </ToastProvider>
    </Provider>
  </ApolloProvider>
);

export default AppWrapper;
