import {
  DEGREES_TO_RADIANS_MULTIPLIER,
  RADIANS_TO_DEGREES_MULTIPLIER,
} from "~/constants";



export const toRadians = (degrees: number | string) =>
  Number(degrees) * DEGREES_TO_RADIANS_MULTIPLIER;

export const toDegrees = (radians: number | string) =>
  Number(radians) * RADIANS_TO_DEGREES_MULTIPLIER;
