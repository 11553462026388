import { useMemo } from "react";

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  getIlikeFilterValue,
  getInFilterValue,
} from "./utils";
import { GetJobTypes, GetJobType } from "~/graphql";
import type { JobTypesQueryResultData, JobTypeQueryResultData } from "~/types";



export const useGetJobTypesQuery =
  makeGetEntitiesQueryHook<JobTypesQueryResultData>(GetJobTypes);

export const useGetJobTypeByIdQuery =
  makeGetEntityByIdQueryHook<JobTypeQueryResultData>(GetJobType);

interface SearchJobTypeQueryVars {
  value?: string;
  ids?: string[];
}

export const useSearchJobTypeQuery = ({
  value,
  ids,
}: SearchJobTypeQueryVars) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { valueIlike: getIlikeFilterValue(value) },
          { idIn: getInFilterValue(ids) },
        ],
      },
    }),
    [value, ids],
  );

  return useGetJobTypesQuery(variables);
};
