import { gql } from "@kv/apollo-client";



export const UserGroupFragment = gql`
  fragment UserGroupFragment on UserGroup {
    __typename
    id
    value
    users {
      id
      name
    }
  }
`;

export const GetUserGroups = gql`
  query GetUserGroups($filters: UserGroupFilter) {
    userGroups(filters: $filters) {
      ...UserGroupFragment
    }
  }
  ${UserGroupFragment}
`;

export const GetUserGroup = gql`
  query GetUserGroup {
    userGroup {
      ...UserGroupFragment
    }
  }
  ${UserGroupFragment}
`;

export const CreateUserGroup = gql`
  mutation CreateUserGroup($input: UserGroupInput!) {
    createUserGroup(input: $input) {
      ...UserGroupFragment
    }
  }
  ${UserGroupFragment}
`;

export const UpdateUserGroup = gql`
  mutation UpdateUserGroup($id: ID!, $input: UserGroupInput!) {
    updateUserGroup(id: $id, input: $input) {
      ...UserGroupFragment
    }
  }
  ${UserGroupFragment}
`;

export const DeleteUserGroups = gql`
  mutation DeleteUserGroups($ids: [ID]) {
    deleteUserGroups(ids: $ids) {
      ids
    }
  }
`;
