export enum RoutePaths {
  LOGIN = "/login",
  LOGIN_SSO = "/login/sso",
  LOGIN_PUBLIC = "/login/public",
  JOBS_LIST = "/",
  JOB = "/jobs/:jobId",
  JOB_ITEM = "/jobs/:jobId/jobItem/:jobItemId/:jobItemStep?",
  CONTENT_ITEMS_LIST = "/content/:contentItemTypeId?",
  CONTENT_ITEM = "/content/:contentItemId",
  ADMIN = "/admin/:adminTab?",
  USER_ACCOUNT = "/user-account",
  POLICIES = "/policies/:policy?",
  SANDBOX = "/staging/sandbox",
}
