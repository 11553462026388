import { gql } from "@kv/apollo-client";



export const ContentItemFragment = gql`
  fragment ContentItemFragment on ContentItem {
    __typename
    id
    contentItemType {
      id
      value
    }
    contentItemUrl
    contentItemDownloadUrl
    contentItemFiles {
      id
      azureFilename
      displayFilename
    }
    thumbnailFile {
      url
      azureFilename
    }
    name
    description
    createdAt
    updatedAt
  }
`;

export const GetContentItems = gql`
  query GetContentItems($filters: ContentItemFilter) {
    contentItems(filters: $filters) {
      ...ContentItemFragment
    }
  }
  ${ContentItemFragment}
`;

export const GetContentItem = gql`
  query GetContentItem($id: ID!) {
    contentItem(id: $id) {
      ...ContentItemFragment
    }
  }
  ${ContentItemFragment}
`;

export const GetContentItemUpload = gql`
  query GetContentItem($id: ID!) {
    contentItemUpload(id: $id) {
      id
      path
      url
      azureFilename
      displayFilename
      downloadLink
    }
  }
`;

export const CreateContentItem = gql`
  mutation CreateContentItem($input: ContentItemInput!) {
    createContentItem(input: $input) {
      ...ContentItemFragment
    }
  }
  ${ContentItemFragment}
`;

export const UpdateContentItem = gql`
  mutation UpdateContentItem($id: ID!, $input: ContentItemInput!) {
    updateContentItem(id: $id, input: $input) {
      ...ContentItemFragment
    }
  }
  ${ContentItemFragment}
`;

export const DeleteContentItems = gql`
  mutation DeleteContentItems($ids: [ID]) {
    deleteContentItems(ids: $ids) {
      ids
    }
  }
`;
