import loadable from "@loadable/component";
import { prop } from "ramda";

import LoadingIndicator from "~/components/LoadingIndicator";



export interface AsyncPageProps {
  page: string;
}

/*
Snippet taken from @loadable/component feature request by ValentinH
>> https://github.com/gregberge/loadable-components/issues/667
*/

interface RetryOptions {
  retries?: number;
  interval?: number;
  exponentialBackoff?: boolean;
}

// taken from https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
export function retry<R>(
  fn: () => Promise<R>,
  { retries = 3, interval = 500, exponentialBackoff = true }: RetryOptions = {},
) {
  return new Promise<R>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retries === 1) {
            reject(error);

            return;
          }

          // Passing on "reject" is the important part
          retry(fn, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
          }).then(resolve, reject);
        }, interval);
      });
  });
}

/* end snippet */

const AsyncPage = loadable(
  ({ page }: AsyncPageProps) =>
    retry(() => import(`./${page}`), { retries: 3 }),
  {
    cacheKey: prop("page"),
    fallback: <LoadingIndicator />,
  },
);

export default AsyncPage;
