import { useMemo } from "react";

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getIlikeFilterValue,
  getInFilterValue,
} from "./utils";
import { GetTags, GetTag, CreateTag, UpdateTag, DeleteTags } from "~/graphql";
import type {
  TagsQueryResultData,
  TagQueryResultData,
  CreateUpdateTagInput,
  CreateUpdateTagMutationResultData,
  DeleteTagsMutationResultData,
} from "~/types";



export const useGetTagsQuery =
  makeGetEntitiesQueryHook<TagsQueryResultData>(GetTags);

export const useGetTagByIdQuery =
  makeGetEntityByIdQueryHook<TagQueryResultData>(GetTag);

interface SearchTagQueryVars {
  value?: string;
  ids?: string[];
}

export const useSearchTagsQuery = ({ value, ids }: SearchTagQueryVars) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { valueIlike: getIlikeFilterValue(value) },
          { idIn: getInFilterValue(ids) },
        ],
      },
    }),
    [value, ids],
  );

  return useGetTagsQuery(variables);
};

export const useCreateTagMutation = makeCreateEntityMutationHook<
  CreateUpdateTagMutationResultData,
  CreateUpdateTagInput
>(CreateTag);

export const useUpdateTagMutation = makeUpdateEntityMutationHook<
  CreateUpdateTagMutationResultData,
  CreateUpdateTagInput
>(UpdateTag);

export const useDeleteTagsMutation =
  makeDeleteEntitiesMutationHook<DeleteTagsMutationResultData>(DeleteTags);
