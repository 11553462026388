import type { QueryResult } from "@kv/apollo-client";
import type { FC } from "react";
import { createContext, useContext, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useGetCurrentUserQuery } from "~/hooks/graphql/users";
import type { CurrentUserQueryResultData } from "~/types";
import { getIsAdminUser } from "~/utils";



interface CurrentUserContextProps {
  loading: QueryResult["loading"];
  error: QueryResult["error"];
  currentUser: CurrentUserQueryResultData["currentUser"] | undefined;
  isAdminUser: boolean;
}

const defaultContextValue: CurrentUserContextProps = {
  loading: false,
  error: undefined,
  currentUser: undefined,
  isAdminUser: false,
};

const CurrentUserContext = createContext(defaultContextValue);

export const CurrentUserProvider: FC = ({ children }) => {
  const location = useLocation();

  const { data, loading, error, refetch } = useGetCurrentUserQuery();

  const currentUser = useMemo(() => data?.currentUser, [data?.currentUser]);

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  const contextValue = useMemo(
    () => ({
      loading,
      error,
      currentUser,
      isAdminUser: getIsAdminUser(currentUser),
    }),
    [loading, error, currentUser],
  );

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUserContext = () => useContext(CurrentUserContext);
