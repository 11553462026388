import type { TruncateOptions } from "lodash";
import { truncate as truncateString } from "lodash";
import { curry, prop } from "ramda";

import {
  parseAndFormatEntityDate,
  renderSimpleEntitiesList,
  renderUserRoleEntity,
} from "../utils";



export const truncate = curry(
  (options: TruncateOptions, value: string | undefined) =>
    truncateString(value, options),
);

const truncateUserEmail = truncate({ length: 35 });

/*
client/src/pages/Job/index.tsx
*/
export const jobsPageColumns = [
  {
    name: "Thumbnail",
    key: "thumbnailFile",
    width: "165px",
  },
  {
    name: "Name / Description",
    key: "name",
    width: "1fr",
    sortable: true,
    sortPath: "contentItem.name",
  },
  {
    name: "Visibility",
    key: "visibility",
    width: "100px",
    sortable: true,
    sortPath: "visibilityType.value",
  },
  {
    name: "Expires",
    key: "expirationDate",
    width: "100px",
    sortable: true,
  },
  {
    name: "",
    key: "actions",
    width: "1fr",
  },
];

// client/src/layouts/ContentItemsList/index.tsx
export const contentItemsColumns = [
  {
    name: "Thumbnail",
    key: "thumbnailFile",
    width: "150px",
    render: prop("url"),
  },
  {
    name: "Name / Description",
    key: "name",
    width: "2fr",
    sortable: true,
  },
  {
    name: "Last Updated",
    key: "updatedAt",
    width: "1fr",
    sortable: true,
  },
  {
    name: "",
    key: "actions",
    width: "165px",
  },
];

/*
client/src/pages/Admin/Users.tsx
*/
export const usersTabColumns = [
  {
    name: "Name",
    key: "name",
    sortable: false,
  },
  {
    name: "Email",
    key: "email",
    width: "2fr",
    renderValue: truncateUserEmail,
    sortable: false,
  },
  {
    name: "Role",
    key: "role",
    renderValue: renderUserRoleEntity,
    sortable: false,
    sortPath: "role.value",
  },
  {
    name: "Groups",
    key: "groups",
    renderValue: renderSimpleEntitiesList,
  },
  {
    name: "Last Active",
    key: "lastActiveAt",
    renderValue: (value: string) => parseAndFormatEntityDate(value),
    sortable: false,
  },
];

/**
 * client/src/pages/JobsList/index.tsx
 */

export const jobsListColumns = [
  {
    name: "Name",
    key: "name",
    width: "3fr",
    sortable: true,
  },
  {
    name: "Business Unit",
    key: "businessUnit",
    width: "2fr",
    sortable: true,
    sortPath: "businessUnit.value",
  },
  {
    name: "Job Type",
    key: "jobType",
    width: "2fr",
    sortable: true,
    sortPath: "jobType.value",
  },
  {
    name: "Tags",
    key: "tags",
    width: "2fr",
  },
];
