import type { DocumentNode } from "@kv/apollo-client";
import { useApolloClient } from "@kv/apollo-client";
import { camelCase } from "lodash";
import { useCallback } from "react";

import { useDebounce } from ".";



export function useValidateNameUniqueness(
  entityName: string,
  queryDoc: DocumentNode,
  queryField?: string,
  field = "names",
) {
  const client = useApolloClient();

  const validateUniqueness = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (filters: any) => {
      const query = await client.query({
        query: queryDoc,
        variables: {
          filters: filters,
        },
        fetchPolicy: "network-only",
      });

      return (
        (query.data?.[queryField ?? camelCase(`${entityName}s`)] ?? [])
          .length === 0 || (`${entityName} ${field} must be unique` as string)
      );
    },
    [client, entityName, field, queryDoc, queryField],
  );

  return useDebounce(
    validateUniqueness,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    300,
    "Outdated validation",
  );
}
