import { capitalize } from "lodash";
import { compose } from "ramda";

import { renderSimpleEntity } from "./generic";
import { UserRoleValues } from "~/enums";
import type { UserRole, SelectOption } from "~/types";



export const getHumanReadableUserRole = (userRoleValue?: UserRoleValues) => {
  switch (userRoleValue) {
    case UserRoleValues.ADMIN:
    case UserRoleValues.VIEWER:
    case UserRoleValues.TEMPORARY: {
      return capitalize(userRoleValue);
    }

    default: {
      return "Unknown";
    }
  }
};

export const renderUserRoleSelectOption = (userRole: SelectOption) =>
  getHumanReadableUserRole(userRole.value as UserRoleValues);

export const renderUserRoleEntity = compose(
  getHumanReadableUserRole,
  renderSimpleEntity,
);

export const getUserRoleDescription = (userRole?: UserRole) => {
  switch (userRole?.value) {
    case UserRoleValues.ADMIN: {
      return (
        "Admins can perform all actions, including managing users and groups," +
        " uploading content, and managing jobs and their access rights."
      );
    }

    case UserRoleValues.VIEWER: {
      return "Viewer";
    }

    case UserRoleValues.TEMPORARY: {
      return "Temporary";
    }

    default: {
      return "";
    }
  }
};
