import { createSlice } from "@reduxjs/toolkit";
import { map, reject } from "ramda";

import { VisibilityFilters } from "./enums";
import type {
  State,
  TodoItem,
  AddActionObj,
  RemoveActionObj,
  UpdateActionObj,
  SetFilterActionObj,
} from "./types";
import { getNewTodoId, getIsTodoById, toggleCompletedById } from "./utils";



export const initialState: State = {
  todos: [],
  visibilityFilter: VisibilityFilters.ALL,
};

export const SLICE_NAME = "todos";

export const {
  reducer,
  actions: { addTodo, toggleTodo, removeTodo, clearTodos, setVisibilityFilter },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addTodo: (state: State, { payload }: AddActionObj): State => ({
      ...state,
      todos: state.todos.concat([
        {
          //* We set default values for `id` and `completed` but allow them
          //* to be overriden in the payload to make testing easier.
          id: getNewTodoId(),
          completed: false,
          ...payload,
        },
      ]),
    }),
    toggleTodo: (state: State, { payload }: UpdateActionObj): State => ({
      ...state,
      todos: map<TodoItem, TodoItem>(
        toggleCompletedById(payload.id),
        state.todos,
      ),
    }),
    removeTodo: (state: State, { payload }: RemoveActionObj): State => ({
      ...state,
      todos: reject<TodoItem, TodoItem[]>(
        getIsTodoById(payload.id),
        state.todos,
      ),
    }),
    clearTodos: (state: State): State => ({
      ...state,
      todos: initialState.todos,
    }),
    setVisibilityFilter: (
      state: State,
      { payload }: SetFilterActionObj,
    ): State => ({
      ...state,
      visibilityFilter: payload,
    }),
  },
});
