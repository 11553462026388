import type { QueryHookOptions } from "@kv/apollo-client";
import { useQuery } from "@kv/apollo-client";

import { parseQueryFilters } from "./filters";
import type { GraphqlNode, BaseQueryVars } from "~/types";



export const makeStandardQueryHook = <
  QueryResults,
  QueryVars extends BaseQueryVars = BaseQueryVars,
>(
  queryNode: GraphqlNode<QueryResults, QueryVars>,
) => {
  const useStandardQuery = (
    variables?: QueryVars,
    options?: QueryHookOptions<QueryResults, QueryVars>,
  ) =>
    useQuery<QueryResults, QueryVars>(queryNode, {
      ...options,
      skip: options?.skip,
      variables: {
        ...options?.variables,
        ...variables,
        filters: variables?.filters
          ? parseQueryFilters(variables.filters)
          : undefined,
      } as QueryVars,
    });

  return useStandardQuery;
};
